<ng-container *transloco="let t; read: 'recommendation.result'">
  @if (data.headers?.minimum || data.headers?.recommended) {
    <div class="md:hidden">
      <lsa-recommendation-selection-mobile
        [hasMinimumData]="!!data.headers?.minimum"
        [hasRecommendedData]="!!data.headers?.recommended"
        [isRecommendedSelected]="isRecommendedSelected"
        (headerSelected)="onHeaderSelectionChange($event)"
      >
      </lsa-recommendation-selection-mobile>
    </div>
  }

  <div class="border border-primary overflow-hidden rounded-lg md:border-none">
    <table mat-table [dataSource]="data.rows">
      <ng-container matColumnDef="field">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="hidden md:table-cell border-none w-60 px-2"
        ></th>
        <td
          mat-cell
          *matCellDef="let element; let isEven = even"
          class="border-none text-title-small text-low-emphasis"
          [ngClass]="{
            'bg-medias-grey-background': isEven
          }"
        >
          {{ t(element.field) }}
        </td>
      </ng-container>

      <mat-radio-group>
        @if (data.headers.minimum) {
          <ng-container matColumnDef="minimum">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="headerColsSpan()"
              class="border-none w-56 relative min-h-min h-full p-0"
              [ngClass]="{
                'hidden md:table-cell': isRecommendedSelected
              }"
            >
              <div class="relative top-0 left-0 w-full h-80 flex items-stretch">
                <lsa-lubricator-header
                  class="w-full"
                  [headerData]="data.headers.minimum"
                  [selected]="!isRecommendedSelected"
                  [isLastColumn]="!data.headers.recommended"
                  [selectValue]="'minimum'"
                  (headerSelected)="onHeaderSelectionChange($event)"
                ></lsa-lubricator-header>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let isEven = even; let isLast = last"
              class="!border-none relative md:table-cell"
              [ngClass]="{
                'bg-medias-grey-background': isEven,
                'hidden md:table-cell': isRecommendedSelected
              }"
            >
              <lsa-recommendation-table-cell
                [border]="true"
                [selected]="!isRecommendedSelected"
                [isLastRow]="isLast"
                [isLastColumn]="!data.headers.recommended"
              >
                <span>
                  {{ element.minimum }}
                </span>
              </lsa-recommendation-table-cell>
            </td>
          </ng-container>
        }
        @if (data.headers.recommended) {
          <ng-container matColumnDef="recommended">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="headerColsSpan()"
              class="border-none w-56 relative h-80 p-0"
              [ngClass]="{
                'hidden md:table-cell': !isRecommendedSelected
              }"
            >
              <div
                class="absolute top-0 left-0 w-full h-full flex items-stretch"
              >
                <lsa-lubricator-header
                  class="w-full"
                  [headerData]="data.headers.recommended"
                  [selected]="isRecommendedSelected"
                  [isLastColumn]="true"
                  [selectValue]="'recommended'"
                  (headerSelected)="onHeaderSelectionChange($event)"
                ></lsa-lubricator-header>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let isEven = even; let isLast = last"
              class="!border-none relative"
              [ngClass]="{
                'bg-medias-grey-background': isEven,
                'hidden md:table-cell': !isRecommendedSelected
              }"
            >
              <lsa-recommendation-table-cell
                [border]="true"
                [selected]="isRecommendedSelected"
                [isLastRow]="isLast"
                [isLastColumn]="true"
              >
                {{ element.recommended }}
              </lsa-recommendation-table-cell>
            </td>
          </ng-container>
        }
      </mat-radio-group>

      <tr
        mat-header-row
        class="items-stretch"
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="items-stretch !h-10"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
</ng-container>
