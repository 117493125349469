<div class="bg-surface-container-high">
  <h2 mat-dialog-title>Dialog with elements</h2>
  <mat-dialog-content
    >This dialog showcases the title, close, content and actions
    elements.</mat-dialog-content
  >
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
