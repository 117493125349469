@if (validResult) {
  <div
    class="flex flex-col lg:flex-row gap-4 md:justify-between"
    *transloco="let t; read: 'recommendation.result'"
  >
    <div class="flex flex-col gap-4 flex-shrink-0">
      <lsa-recommendation-table
        [data]="validResult.lubricators | lsaRecommendationTableData"
        (recommendedSelectedChange)="onRecommendedSelectedChange($event)"
      ></lsa-recommendation-table>
      <div>Your Inputs</div>
    </div>
    <div class="flex-1 md:max-w-[50%]">
      <h2 class="text-title-small">
        {{
          t('heading', {
            designation: isRecommendedSelected
              ? validResult.lubricators.recommendedLubricator.name
              : validResult.lubricators.minimumRequiredLubricator.name
          })
        }}
      </h2>
      <lsa-accessory-table
        [accessories]="
          isRecommendedSelected
            ? validResult.lubricators.recommendedLubricator.bundle
            : validResult.lubricators.minimumRequiredLubricator.bundle
        "
      />
      <div
        class="w-full flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-4 mt-4"
      >
        <button
          mat-flat-button
          class="w-full md:w-auto tertiary-button bg-secondary-legacy-500"
        >
          {{ t('share') }}
          <mat-icon>bookmark</mat-icon>
        </button>
        <button mat-flat-button class="w-full md:w-auto">
          {{ t('addToCart') }}
          <mat-icon>shopping_cart</mat-icon>
        </button>
      </div>
    </div>
  </div>
} @else {
  <p>Error: {{ errorInstance.name }}</p>
}
