<div
  *transloco="let t"
  class="flex h-screen w-screen max-w-full justify-center lubricator-selection-assistant"
>
  <div class="w-screen md:m-5">
    <h1 class="font-normal text-h6 sm:text-h5 text-carbon-grey mb-3">
      {{ t('appTitle') }}
    </h1>
    <mat-divider class="mb-3 sm:mb-9"></mat-divider>
    <lsa-recommendation-container></lsa-recommendation-container>
  </div>
</div>
<lsa-presentation-ux></lsa-presentation-ux>
