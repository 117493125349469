<div
  class="flex flex-col gap-6"
  *transloco="let t; read: 'recommendation.lubricationPoints'"
>
  <div class="py-8 flex flex-col gap-6">
    <span class="text-h6 text-medium-emphasis">{{
      t('questionLubricationPoints')
    }}</span>
    <lsa-radio-button-group
      [options]="lubricationPointsOptions"
      [control]="lubricationPointsForm.controls.lubricationPoints"
    ></lsa-radio-button-group>
  </div>

  <mat-divider></mat-divider>

  <div class="flex flex-col gap-6">
    <span class="text-h6 text-medium-emphasis">{{
      t('questionRelubricationQuantity')
    }}</span>
    <lsa-radio-button-group
      [options]="lubricationIntervalOptions"
      [control]="lubricationPointsForm.controls.lubricationInterval"
    >
      <ng-template radioOptionContent="lubricationQuantity">
        <div
          class="border border-border md:border-none md:bg-transparent rounded-lg gap-4 pt-4 px-2 md:p-0 flex-1 bg-surface border-primary"
        >
          <mat-form-field
            appearance="outline"
            class="max-w-sm w-full border-border pt-2"
          >
            <mat-label>{{ t('lubricationQuantity') }}</mat-label>
            <mat-select
              [formControl]="lubricationPointsForm.controls.lubricationQty"
            >
              @for (
                lubricationQuantity of lubricationQuantityOptions;
                track lubricationQuantity
              ) {
                <mat-option [value]="lubricationQuantity">{{
                  t('lubricationQuantityValue', { value: lubricationQuantity })
                }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>
    </lsa-radio-button-group>
  </div>

  <mat-divider></mat-divider>

  <div class="flex flex-col gap-6">
    <span class="text-h6 text-medium-emphasis">{{
      t('questionPipeLength')
    }}</span>
    <lsa-radio-button-group
      [options]="pipeLengthOptions"
      [control]="lubricationPointsForm.controls.pipeLength"
    ></lsa-radio-button-group>
  </div>

  <mat-divider></mat-divider>

  <div class="flex flex-col gap-6">
    <span class="text-h6 text-medium-emphasis">{{ t('questionOptime') }}</span>
    <lsa-radio-button-group
      [options]="optimeOptions"
      [control]="lubricationPointsForm.controls.optime"
    ></lsa-radio-button-group>
  </div>
</div>
