<div
  class="flex flex-col lg:flex-row gap-4 md:justify-between"
  *transloco="let t; read: 'recommendation.result'"
>
  <div class="flex flex-col gap-4 flex-shrink-0">
    <lsa-recommendation-table
      [data]="recommendationResult.lubricators | lsaRecommendationTableData"
      (recommendedSelectedChange)="onRecommendedSelectedChange($event)"
    ></lsa-recommendation-table>
    <div>Your Inputs</div>
  </div>
  <div class="flex-1 md:max-w-[50%]">
    <h2 class="text-subtitle-2">
      {{
        t('heading', {
          designation: isRecommendedSelected
            ? recommendationResult.lubricators.recommendedLubricator.name
            : recommendationResult.lubricators.minimumRequiredLubricator.name
        })
      }}
    </h2>
    <lsa-accessory-table
      [accessories]="
        isRecommendedSelected
          ? recommendationResult.lubricators.recommendedLubricator.bundle
          : recommendationResult.lubricators.minimumRequiredLubricator.bundle
      "
    />
    <div
      class="w-full flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-4 mt-4"
    >
      <button
        mat-flat-button
        color="tertiary"
        class="w-full md:w-auto bg-secondary-legacy-500"
      >
        {{ t('share') }}
        <mat-icon>bookmark</mat-icon>
      </button>
      <button mat-flat-button color="primary" class="w-full md:w-auto">
        {{ t('addToCart') }}
        <mat-icon>shopping_cart</mat-icon>
      </button>
    </div>
  </div>
</div>
