<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="tooltipOrigin"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayOffsetY]="tooltipOffsetY"
  (detach)="connectedOverlayDetach()"
>
  <div
    #tooltip
    class="bg-surface-container text-caption text-on-surface-variant whitespace-pre-line rounded-xl px-4 py-2 drop-shadow-lg"
    [ngClass]="{
      '!opacity-0 transition-opacity duration-500': fadeOut,
      '!opacity-100': !fadeOut
    }"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
