<div
  class="flex flex-col gap-6 mb-8"
  *transloco="let t; read: 'recommendation.lubrication'"
>
  <div class="py-8 flex flex-col gap-6">
    <span class="text-h6 text-medium-emphasis">
      {{ t('questionLubricantType') }}</span
    >
    <lsa-radio-button-group
      [options]="lubricantRadioOptions"
      [control]="lubricantForm.controls.lubricantType"
    ></lsa-radio-button-group>
  </div>

  @if (lubricantForm.controls.lubricantType.value === arcanolType) {
    <mat-divider></mat-divider>
    <div class="flex flex-col gap-6">
      <span class="text-h6 text-medium-emphasis">
        {{ t('questionSchaefflerArcanol') }}</span
      >
      @if (greases) {
        <schaeffler-select
          appearance="outline"
          class="max-w-sm"
          [label]="t('label')"
          [stringOptions]="greases"
          [control]="lubricantForm.controls.grease"
          [resetButton]="false"
          [hint]="t('searchHint')"
          [filterFn]="filterFn"
          name="greaseSelector"
        ></schaeffler-select>
      }
    </div>
  }
</div>
