<div class="m-2">
  <div class="grid grid-cols-4 gap-2">
    <!-- Primary colors-->
    <div class="flex flex-col gap-2">
      <div class="bg-primary h-10 p-3">
        <p class="text-on-primary" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-primary">
        <p class="text-primary" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-primary-container h-10">
        <p class="text-on-primary-container" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-primary-container h-10 p-3">
        <p class="text-primary-container" lsaAppBackgroundColor></p>
      </div>

      <div class="grid grid-cols-2 h-20">
        <div class="bg-primary-fixed h-full p-3">
          <p class="text-on-primary-fixed" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-primary-fixed-dim h-full p-3">
          <p class="text-on-primary-fixed" lsaAppBackgroundColor></p>
        </div>
      </div>
      <div class="bg-on-primary-fixed h-10 p-3">
        <p class="text-primary-fixed" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-on-primary-fixed-variant h-10 p-3">
        <p class="text-on-primary" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-surface-dim h-10 p-3">
        <p class="text-on-surface" lsaAppBackgroundColor></p>
      </div>

      <div class="grid grid-cols-2 h-20">
        <div class="bg-surface-container-lowest h-full p-3">
          <p class="text-on-surface" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-surface-container-low h-full p-3">
          <p class="text-on-surface" lsaAppBackgroundColor></p>
        </div>
      </div>

      <div class="grid grid-cols-2 h-20">
        <div class="bg-on-surface h-full p-3">
          <p class="text-surface-container-lowest" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-on-surface-variant h-full p-3">
          <p class="text-surface-container-lowest" lsaAppBackgroundColor></p>
        </div>
      </div>
    </div>

    <!-- Secondary colors-->
    <div class="flex flex-col gap-2">
      <div class="bg-secondary h-10 p-3">
        <p class="text-on-secondary" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-secondary">
        <p class="text-secondary" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-secondary-container h-10">
        <p class="text-on-secondary-container" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-secondary-container h-10 p-3">
        <p class="text-secondary-container" lsaAppBackgroundColor></p>
      </div>

      <div class="grid grid-cols-2 h-20">
        <div class="bg-secondary-fixed h-full p-3">
          <p class="text-on-secondary-fixed" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-secondary-fixed-dim h-full p-3">
          <p class="text-on-secondary-fixed" lsaAppBackgroundColor></p>
        </div>
      </div>
      <div class="bg-on-secondary-fixed h-10 p-3">
        <p class="text-secondary-fixed" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-on-secondary-fixed-variant h-10 p-3">
        <p class="text-on-secondary" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-surface h-10 p-3">
        <p class="text-on-surface" lsaAppBackgroundColor></p>
      </div>

      <div class="grid grid-cols-2 h-20">
        <div class="bg-surface-container h-full p-3">
          <p class="text-on-surface" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-surface-container-high h-full p-3">
          <p class="text-on-surface" lsaAppBackgroundColor></p>
        </div>
      </div>
      <div class="bg-outline h-20 p-3">
        <p class="text-surface" lsaAppBackgroundColor></p>
      </div>
    </div>

    <!-- Tertiary colors -->
    <div class="flex flex-col gap-2">
      <div class="bg-tertiary h-10 p-3">
        <p class="text-on-tertiary" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-tertiary">
        <p class="text-tertiary" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-tertiary-container h-10">
        <p class="text-on-tertiary-container" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-tertiary-container h-10 p-3">
        <p class="text-tertiary-container" lsaAppBackgroundColor></p>
      </div>

      <div class="grid grid-cols-2 h-20">
        <div class="bg-tertiary-fixed h-full p-3">
          <p class="text-on-tertiary-fixed" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-tertiary-fixed-dim h-full p-3">
          <p class="text-on-tertiary-fixed" lsaAppBackgroundColor></p>
        </div>
      </div>
      <div class="bg-on-tertiary-fixed h-10 p-3">
        <p class="text-tertiary-fixed" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-on-tertiary-fixed-variant h-10 p-3">
        <p class="text-tertiary-fixed-dim" lsaAppBackgroundColor>/p></p>
      </div>

      <div class="bg-surface-bright h-10 p-3">
        <p class="text-on-surface" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-surface-container-highest h-20 p-3">
        <p class="text-on-surface" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-outline-variant h-20 p-3">
        <p class="text-surface" lsaAppBackgroundColor></p>
      </div>
    </div>

    <!-- Error colors-->
    <div class="flex flex-col gap-2">
      <div class="bg-error h-10 p-3">
        <p class="text-on-error" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-error">
        <p class="text-error" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-error-container h-10">
        <p class="text-on-error-container" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-on-error-container h-10 p-3">
        <p class="text-error-container" lsaAppBackgroundColor></p>
      </div>
      <div class="bg-inverse-surface h-10 p-3 mt-44">
        <p class="text-surface" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-inverse-on-surface h-10 p-3">
        <p class="text-inverse-surface" lsaAppBackgroundColor></p>
      </div>

      <div class="bg-inverse-primary h-10 p-3">
        <p class="text-on-primary-container" lsaAppBackgroundColor></p>
      </div>

      <div class="grid grid-cols-2 h-20 gap-2">
        <div class="bg-scrim h-full p-3">
          <p class="text-surface" lsaAppBackgroundColor></p>
        </div>
        <div class="bg-shadow h-full p-3">
          <p class="text-surface" lsaAppBackgroundColor></p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row gap-6 py-3">
    <schaeffler-tag
      type="category3"
      [withDot]="true"
      [withBorder]="withBorderValue"
    >
      <p class="px-2">test tag</p>
    </schaeffler-tag>

    <schaeffler-tag type="category2" [value]="'test tag category 2'">
    </schaeffler-tag>
  </div>

  <div class="flex">
    <div class="h20 bg-primary bg-opacity-[0.07]">
      <p>test primary with opacity</p>
    </div>

    <div class="h20 bg-primary opacity-90">
      <p>test primary with opacity</p>
    </div>

    <div class="bg-secondary-900 h-20">
      <p>test secondary with opacity</p>
    </div>

    <div class="bg-secondary h-20">
      <p>test secondary with opacity</p>
    </div>

    <div class="bg-secondary-400 h-20">
      <p>test secondary with opacity</p>
    </div>
  </div>

  <div class="flex">
    <button class="tertiary-button" mat-flat-button>test button</button>
    <button class="secondary-button" mat-flat-button>
      test secondary button
    </button>
    <button class="tonal-button" mat-flat-button>tonal button</button>
    <div class="h-20 bg-surface">
      <button class="tonal-button" mat-flat-button disabled>
        tonal button disabled
      </button>
    </div>
  </div>

  <div class="bg-surface m-3 p-3">
    <p>
      <mat-slide-toggle [checked]="darkMode" (toggleChange)="turnOnDarkMode()"
        >change mode</mat-slide-toggle
      >
    </p>
    <div class="grid grid-cols-2">
      <div>
        <h2 class="text-on-surface">Angular Materials Components - buttons</h2>
        <h3 class="text-on-surface">Flat buttons "Filled buttons"</h3>
        <section>
          <div class="flex gap-2">
            <button mat-flat-button>Label</button>
            <a mat-flat-button href="https://www.google.com/" target="_blank"
              >Label</a
            >
            <button mat-flat-button disabled>Label</button>
          </div>
        </section>

        <h3 class="text-on-surface">Stroked buttons "Outlined buttons"</h3>
        <div class="flex gap-2">
          <button mat-stroked-button>Basic</button>
          <a mat-stroked-button href="https://www.google.com/" target="_blank"
            >Link</a
          >
          <button mat-stroked-button disabled>Disabled</button>
        </div>

        <h3 class="text-on-surface">Basic buttons "Text buttons"</h3>
        <div class="flex gap-2">
          <button mat-button>Basic</button>
          <a mat-button href="https://www.google.com/" target="_blank">Link</a>
          <button mat-button disabled>Disabled</button>
        </div>
        <h3 class="text-on-surface">Raised buttons "Elevated buttons"</h3>
        <div class="flex gap-2">
          <button mat-raised-button>Basic</button>
          <a mat-raised-button href="https://www.google.com/" target="_blank"
            >Link</a
          >
          <button mat-raised-button disabled>Disabled</button>
        </div>
        <h3 class="text-on-surface">
          Tonal buttons, fab, mini fab and extended fab "Tonal, fab, extended
          FAB"
        </h3>

        <div class="flex">
          <button mat-flat-button class="tonal-button">
            custom tonal button
          </button>
        </div>

        <h3 class="text-on-surface">
          FAB buttons ( floating action buttons ) "FAB buttons"
        </h3>
        <p class="text-error">
          (Surface variant is not out of the box, icon button is similar to FAB.
          If required can be created)
        </p>
        <h4 class="text-on-surface">Small fabs</h4>
        <div class="flex gap-2">
          <button
            mat-mini-fab
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            class="secondary-fab"
            mat-mini-fab
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            class="tertriary-fab"
            mat-mini-fab
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>

        <h4 class="text-on-surface">Fabs</h4>
        <div class="flex gap-2">
          <button mat-fab aria-label="Example icon button with a menu icon">
            <mat-icon>edit</mat-icon>
          </button>

          <button
            class="secondary-fab"
            mat-fab
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            class="tertriary-fab"
            mat-fab
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>

        <h4 class="text-on-surface">Extended Fabs</h4>
        <div class="flex gap-2">
          <button mat-fab extended>
            <mat-icon>edit</mat-icon>
            Primary
          </button>
          <button mat-fab extended class="secondary-fab">
            <mat-icon>edit</mat-icon>
            Secondary
          </button>

          <button mat-fab extended class="tertriary-fab">
            <mat-icon>edit</mat-icon>
            Tertiary
          </button>
        </div>
        <h4 class="text-error">Large Fabs - not out of the box</h4>

        <h3 class="text-on-surface">Icon buttons</h3>
        <p class="text-error">
          more variations can be created manually plus some extension is added
          in the newest version of library
          <a rel="https://github.com/angular/components/pull/29433/files">
            here</a
          >
        </p>
        <div class="flex gap-2">
          <button
            class="flex"
            mat-icon-button
            aria-label="Example icon button with a vertical three dot icon"
          >
            <mat-icon>settings</mat-icon>
          </button>

          <button
            class="icon-button-primary flex"
            mat-icon-button
            aria-label="Example icon button with a vertical three dot icon"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <div>
          <h4 class="text-on-surface">Radio buttons</h4>
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="1">Option 1</mat-radio-button>
            <mat-radio-button value="2">Option 2</mat-radio-button>
          </mat-radio-group>
        </div>

        <h3 class="text-on-surface">Segmented buttons</h3>
        <div class="flex gap-2">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="bold">Bold</mat-button-toggle>
            <mat-button-toggle value="italic">Italic</mat-button-toggle>
            <mat-button-toggle value="underline">Underline</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <h2 class="text-on-surface">Anguar material Components - Menu</h2>
        <button mat-button [matMenuTriggerFor]="menu">Menu</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>Item 1</button>
          <button mat-menu-item>Item 2</button>
        </mat-menu>
      </div>
      <h4>mat-select</h4>
      <mat-form-field appearance="outline">
        <mat-label>Choose an option</mat-label>
        <mat-select [disabled]="disableSelect.value">
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <h2 class="text-on-surface">Anguar material Components - checkbox</h2>
        <section class="example-section" [formGroup]="toppings">
          <h4 class="text-on-surface text-headline-small">
            Select your toppings:
          </h4>
          <p>
            <mat-checkbox formControlName="pepperoni">Primary</mat-checkbox>
          </p>
          <p>
            <mat-checkbox [indeterminate]="true" formControlName="extracheese"
              >Intermediate initially</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox class="error-checkbox" formControlName="mushroom"
              >Error checkbox</mat-checkbox
            >
          </p>
        </section>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <h2 class="text-on-surface">Card component</h2>
        <mat-card appearance="outlined">
          <mat-card-content class="text-on-surface"
            >Simple card</mat-card-content
          >
        </mat-card>
      </div>
    </div>
    <div class="flex flex-col items-start">
      <h2 class="text-on-surface">Badges</h2>
      <div
        matBadge="1"
        matBadgeSize="small"
        class="demo-section text-on-surface"
      >
        Text with small badge
      </div>
      <div
        matBadge="3"
        matBadgeOverlap="false"
        class="demo-section text-on-surface"
      >
        Text with a badge
      </div>
      <div
        matBadge="32"
        matBadgeSize="large"
        matBadgeOverlap="false"
        class="demo-section text-on-surface"
      >
        Text with large badge
      </div>
      <div
        matBadge="32"
        matBadgeSize="large"
        class="demo-section text-on-surface"
      >
        Text with large with overlap
      </div>
    </div>
    <div class="grid grid-cols-2">
      <h2 class="text-on-surface">Date picker</h2>
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input appearance="outline" matInput [matDatepicker]="picker" />
        <mat-hint class="text-on-surface">MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div>
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate placeholder="Start date" />
            <input matEndDate placeholder="End date" />
          </mat-date-range-input>
          <mat-hint class="text-on-surface">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <h2 class="text-on-surface">Dialog</h2>
      <button mat-button (click)="openDialog()">Launch dialog</button>
    </div>
    <div class="grid grid-cols-2">
      <h2 class="text-on-surface">dividers</h2>
      <mat-divider></mat-divider>
      <p class="text-on-surface">some content</p>
      <mat-divider [vertical]="true" class="h-12"></mat-divider>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">list</h2>
      <div>
        <mat-selection-list #shoes>
          @for (shoe of typesOfShoes; track shoe) {
            <mat-list-option>{{ shoe }}</mat-list-option>
          }
        </mat-selection-list>

        <p class="text-on-surface">
          Options selected: {{ shoes.selectedOptions.selected.length }}
        </p>
      </div>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">tabs</h2>
      <mat-tab-group>
        <mat-tab label="First"> Content 1 </mat-tab>
        <mat-tab label="Second"> Content 2 </mat-tab>
        <mat-tab label="Third"> Content 3 </mat-tab>
      </mat-tab-group>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">stepper</h2>
      <button
        mat-raised-button
        (click)="isLinear = !isLinear"
        id="toggle-linear"
      >
        {{ !isLinear ? 'Enable linear mode' : 'Disable linear mode' }}
      </button>
      <mat-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="firstCtrl"
                required
              />
            </mat-form-field>
            <div>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
          <form [formGroup]="secondFormGroup">
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input
                matInput
                formControlName="secondCtrl"
                placeholder="Ex. 1 Main St, New York, NY"
                required
              />
            </mat-form-field>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <p>You are now done.</p>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">Chips</h2>
      <mat-chip-set class="primary-chip" aria-label="Fish selection">
        <mat-chip>One fish</mat-chip>
        <mat-chip [highlighted]="true">Two fish</mat-chip>
        <mat-chip [highlighted]="true" class="">Highlighted chip </mat-chip>
        <mat-chip>
          <img
            matChipAvatar
            src="https://material.angular.io/assets/img/examples/shiba1.jpg"
            alt="Photo of a Shiba Inu"
          />
          Dog one
        </mat-chip>
        <mat-chip disabled>Four fish</mat-chip>
      </mat-chip-set>
      <div>
        <mat-chip-option [selected]="true">Selectable Chip</mat-chip-option>
      </div>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">Progress bar</h2>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">Spinner</h2>
      <mat-spinner></mat-spinner>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">Tooltips</h2>
      <button
        mat-raised-button
        matTooltip="Info about the action"
        aria-label="Button that displays a tooltip when focused or hovered over"
      >
        Action
      </button>

      <h2 class="text-on-surface">Rich tooltip</h2>
      <div class="flex ml-1 mb-20 flex-shrink-0 text-on-surface">
        <p>Hover over the icon to see tooltip</p>
        <mat-icon
          cdkOverlayOrigin
          #infoIcon="cdkOverlayOrigin"
          [inline]="true"
          color="inherit"
          class="cursor-help ml-3"
          >info_outline</mat-icon
        >
      </div>

      <schaeffler-rich-tooltip
        [tooltipOrigin]="infoIcon"
        [tooltipShowDelay]="200"
        [tooltipHideDelay]="200"
        class=""
      >
        <div class="flex flex-col">
          <h3 class="text-title-small text-on-surface-variant">Title</h3>
          <p class="text-caption text-on-surface-variant">
            Supporting line ipsum lorem
          </p>
          <div class="flex flex-row">
            <button mat-button>Action 1</button>
            <button mat-button>Action 2</button>
          </div>
        </div>
      </schaeffler-rich-tooltip>
    </div>
    <div class="flex flex-col">
      <h2>Sliders</h2>
      <mat-slider>
        <input matSliderThumb />
      </mat-slider>
      <mat-slider min="200" max="500">
        <input value="300" matSliderStartThumb />
        <input value="400" matSliderEndThumb />
      </mat-slider>
      <mat-slider
        min="0"
        max="100000"
        step="1000"
        showTickMarks
        discrete
        [displayWith]="formatLabel"
      >
        <input matSliderThumb />
      </mat-slider>
    </div>
    <div class="flex flex-col">
      <h2 class="text-on-surface">Snackbar</h2>
      <mat-form-field>
        <mat-label>Message</mat-label>
        <input matInput value="Disco party!" #message />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Action</mat-label>
        <input matInput value="Dance" #action />
      </mat-form-field>

      <button
        mat-stroked-button
        (click)="openSnackBar(message.value, action.value)"
      >
        Show snack-bar
      </button>
    </div>
    <div class="text-on-surface">
      Captions:
      <a href="https://material.angular.io/guide/typography">
        based on classes defined here</a
      >,
    </div>
    <div>
      <div
        class="text-display-small md:text-display-medium lg:text-display-large text-on-surface"
      >
        Responsive size text
      </div>

      <div class="text-display-large text-on-surface">
        text display large III
      </div>
      <div class="text-display-medium text-on-surface">test display medium</div>
      <div class="text-display-small text-on-surface">test display small</div>
      <div class="text-headline-large text-on-surface">
        test headline large - not in UX design redundant?
      </div>
      <div class="text-headline-medium text-on-surface">
        test headline medium
      </div>
      <div class="text-headline-small text-on-surface">test headline small</div>
      <div class="text-title-large text-on-surface">test title large</div>
      <div class="text-title-medium text-on-surface">test title medium</div>
      <div class="text-title-small text-on-surface">test title small</div>
      <div class="text-body-large text-on-surface">test body large</div>
      <div class="text-body-medium text-on-surface">test body medium</div>
      <div class="text-body-small text-on-surface">test body small</div>
      <div class="text-label-large text-on-surface">test label large</div>
      <div class="text-label-medium text-on-surface">test label medium</div>
      <div class="text-label-small text-on-surface">test label small</div>
    </div>
  </div>
</div>
