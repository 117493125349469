<mat-radio-button
  class="cursor-pointer bg-disabled-overlay rounded-xl w-fit"
  [value]="value"
  [checked]="selected"
>
  <span
    class="uppercase text-[8px]/[16px] font-bold tracking-wide h-full text-center block"
  >
    {{ title}}
  </span>
</mat-radio-button>
