<div
  *transloco="let t"
  class="flex min-h-screen w-screen max-w-full justify-center bg-surface"
>
  <div class="w-screen md:m-5">
    <h1
      class="font-normal text-title-large sm:text-headline-small text-on-surface-variant mb-3"
    >
      {{ t('appTitle') }}
    </h1>
    <mat-divider class="mb-3 sm:mb-9"></mat-divider>
    <lsa-recommendation-container></lsa-recommendation-container>
  </div>
</div>
