/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/member-ordering */
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { DOCUMENT, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  model,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslocoModule } from '@jsverse/transloco';
import { BackgroundColorDirective } from '@lsa/shared/directives/background-color.directive';

import { RichTooltipComponent } from '@schaeffler/rich-tooltip';
import { TagComponent } from '@schaeffler/tag';
@Component({
  templateUrl: 'dialog-example.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogExampleComponent {}

@Component({
  selector: 'lsa-presentation-ux',
  templateUrl: './presentation-ux.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    BackgroundColorDirective,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatRadioModule,
    TagComponent,
    MatCardModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    JsonPipe,
    MatListModule,
    MatTabsModule,
    MatStepperModule,
    MatTooltipModule,
    RichTooltipComponent,
    CdkOverlayOrigin,
    MatSliderModule,
  ],
  providers: [provideNativeDateAdapter()],
})
export class PresentationUxComponent {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialog: MatDialog
  ) {}
  disableSelect = new FormControl(false);
  typesOfShoes: string[] = [
    'Boots',
    'Clogs',
    'Loafers',
    'Moccasins',
    'Sneakers',
  ];
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _snackBar = inject(MatSnackBar);

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;

  openDialog() {
    this.dialog.open(DialogExampleComponent);
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return `${Math.round(value / 1000)}k`;
    }

    return `${value}`;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  public readonly toppings = this._formBuilder.group({
    pepperoni: false,
    extracheese: false,
    mushroom: false,
  });

  selectToppings = new FormControl('');

  public withBorderValue = true;

  public darkMode = false;
  readonly checked = model(false);
  readonly indeterminate = model(false);

  public turnOnDarkMode(): void {
    this.darkMode = !this.darkMode;

    if (this.darkMode) {
      this.document.querySelectorAll('html')[0].classList.add('dark');
    } else {
      this.document.querySelectorAll('html')[0].classList.remove('dark');
    }
  }
}
